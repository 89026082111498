import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const ConfirmDialog = ({ message, title, onDialogClose, btnOKLabel, btnCancelLabel, dialogProps }: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleOk = () => onDialogClose({ success: true });
  const handleCancel = () => onDialogClose({ success: false });

  return (
    <Dialog {...dialogProps} open={true} fullScreen={fullScreen} onClose={handleCancel}>
      <DialogTitle sx={{ fontSize: '1.25rem' }}>{title}</DialogTitle>
      <DialogContent>
        {typeof message === 'string' ? <DialogContentText>{message}</DialogContentText> : message}
      </DialogContent>
      <DialogActions>
        {btnOKLabel !== null && (
          <Button variant="contained" onClick={handleOk}>
            {btnOKLabel || 'Yes'}
          </Button>
        )}
        {btnCancelLabel !== null && (
          <Button variant="outlined" onClick={handleCancel}>
            {btnCancelLabel || 'No'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface IProps {
  classes: any;
  message: string;
  title?: string;
  btnOKLabel?: string | undefined | null; // null means to Hide.
  btnCancelLabel?: string | undefined | null;
  onDialogClose: any;
  size?: 'sm' | 'lg' | 'xl';
  dialogProps?: DialogProps;
}

export default ConfirmDialog;
