import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogActions, IconButton } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const InfoDialog = ({ message, title, onDialogClose, dialogProps }: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleCancel = () => onDialogClose({ success: false });

  return (
    <Dialog {...dialogProps} open={true} fullScreen={fullScreen} onClose={handleCancel}>
      <DialogTitle sx={{ fontSize: '1.25rem' }}>
        <Box sx={{ mt: 1 }}>{title}</Box>
        <IconButton
          onClick={handleCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: 0 }}></DialogActions>
    </Dialog>
  );
};

interface IProps {
  classes: any;
  message: string;
  title?: string;
  onDialogClose: any;
  size?: 'sm' | 'lg' | 'xl';
  dialogProps?: DialogProps;
}

export default InfoDialog;
