import { LazyExoticComponent, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SignIn, { useAccessControl } from 'features/auth';
import NotFound from 'features/errors';
import UnauthorizedPage from 'features/errors/Unauthorized';
import SaleSummaryLayout from 'features/sale-summary/SaleSummaryLayout';

import AuthLayout from './auth-layout';
import { ClientRoles } from './constants/roles';
import AppRoutes from './constants/routes';
import { AppContainer } from './layout';
import { WebstoreContainer } from './layout/WebstoreContainer';
import SignOut from 'features/auth/SignOut';

const ForgotPassword = lazy(() => import('features/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('features/auth/ResetPassword'));

const DialogExample = lazy(() => import('features/examples/DialogExample'));
const FormExample = lazy(() => import('features/examples/FormExample'));
const DataListExample = lazy(() => import('features/examples/list/ClientList'));

const Divisions = lazy(() => import('features/divisions/DivisionList'));
const DivisionDetail = lazy(() => import('features/divisions/DivisionDetail'));

const LocationList = lazy(() => import('features/locations/LocationList'));
const LocationDetail = lazy(() => import('features/locations/LocationDetail'));

const ClientList = lazy(() => import('features/clients/ClientList'));
const SystemRolesClientDetailContainer = lazy(() => import('features/clients/SystemRolesClientDetailContainer'));
const ClientRolesClientDetail = lazy(() => import('features/clients/ClientRolesClientDetail'));

const UserList = lazy(() => import('features/users/UserList'));
const UserDetail = lazy(() => import('features/users/UserDetail'));

const CatalogList = lazy(() => import('features/catalogs/CatalogList'));
const CatalogDetail = lazy(() => import('features/catalogs/CatalogDetail'));

const OrderHistory = lazy(() => import('features/orders/OrderHistory'));
const OrderDetail = lazy(() => import('features/orders/OrderDetail'));
const TopProducts = lazy(() => import('features/sale-summary/TopProducts'));

const AdminWebstorePreview = lazy(() => import('features/webstores/AdminWebstorePreview'));
const ClientWebstore = lazy(() => import('features/webstores/ClientWebstore'));

const ShoppingCart = lazy(() => import('features/shopping-carts/ShoppingCart'));
const ReviewOrder = lazy(() => import('features/shopping-carts/ReviewOrder'));

const PaymentRedirect = lazy(() => import('features/payments/PaymentRedirect'));
const WebstoreImporter = lazy(() => import('features/webstore-importer/WebstoreImporter'));
const UserImporter = lazy(() => import('features/user-importer/UserImporter'));

const ScheduledEmployees = lazy(() => import('features/employees/ScheduledEmployees'));

const renderPage = (PageComponent: LazyExoticComponent<(props?: any) => JSX.Element>) => (
  <Suspense fallback={<></>}>
    <PageComponent />
  </Suspense>
);

export const AppRouters = () => {
  const { isPermitted: isClientStaff } = useAccessControl({ defaultAllowedRoles: ClientRoles });
  const CurrentLayout = isClientStaff ? <WebstoreContainer /> : <AppContainer />;

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />}>
        <Route index element={<SignIn />} />
        <Route path={`${AppRoutes.SignIn}`} element={<SignIn />} />
        <Route path={`${AppRoutes.SignOut}`} element={<SignOut />} />
        <Route path={`${AppRoutes.ForgotPassword}`} element={renderPage(ForgotPassword)} />
        <Route path={`${AppRoutes.ResetPassword}`} element={renderPage(ResetPassword)} />
      </Route>

      <Route path="/" element={CurrentLayout}>
        <Route path={AppRoutes.SalesSummary} element={<SaleSummaryLayout />}>
          <Route index element={renderPage(OrderHistory)} />
          <Route path={AppRoutes.OrderHistoryList} element={renderPage(OrderHistory)} />
          <Route path={AppRoutes.TopProductList} element={renderPage(TopProducts)} />
        </Route>

        <Route path={AppRoutes.DialogExamples} element={renderPage(DialogExample)} />
        <Route path={AppRoutes.FormExamples} element={renderPage(FormExample)} />
        <Route path={AppRoutes.DataListExamples} element={renderPage(DataListExample)} />
        <Route path={AppRoutes.DivisionList} element={renderPage(Divisions)} />
        <Route path={AppRoutes.DivisionDetail}>
          <Route index element={renderPage(DivisionDetail)} />
          <Route path=":divisionId" element={renderPage(DivisionDetail)} />
        </Route>
        <Route path={AppRoutes.OrderDetail}>
          <Route path=":orderId" element={renderPage(OrderDetail)} />
        </Route>
        <Route path={AppRoutes.LocationList} element={renderPage(LocationList)} />
        <Route path={AppRoutes.LocationDetail}>
          <Route index element={renderPage(LocationDetail)} />
          <Route path=":locationId" element={renderPage(LocationDetail)} />
        </Route>
        <Route path={AppRoutes.ClientList}>
          {!isClientStaff && <Route index element={renderPage(ClientList)} />}
          <Route path={`:clientId/${AppRoutes.DivisionList}`} element={renderPage(Divisions)} />
          <Route path={`:clientId/${AppRoutes.DivisionDetail}`} element={renderPage(DivisionDetail)} />
          <Route path={`:clientId/${AppRoutes.LocationDetail}`} element={renderPage(LocationDetail)} />
        </Route>
        <Route path={AppRoutes.ClientDetail}>
          <Route
            index
            element={renderPage(isClientStaff ? ClientRolesClientDetail : SystemRolesClientDetailContainer)}
          />
          <Route path=":clientId" element={renderPage(SystemRolesClientDetailContainer)} />
        </Route>
        <Route path={AppRoutes.UserList} element={renderPage(UserList)} />
        <Route path={AppRoutes.UserDetail}>
          <Route index element={renderPage(UserDetail)} />
          <Route path=":userId" element={renderPage(UserDetail)} />
        </Route>
        {!isClientStaff && (
          <Route path={`${AppRoutes.CatalogList}`}>
            <Route index element={renderPage(CatalogList)} />
            <Route path=":catalogId" element={renderPage(CatalogDetail)} />
          </Route>
        )}
        <Route path={`${AppRoutes.PaymentRedirect}`} element={renderPage(PaymentRedirect)} />
        {!isClientStaff && <Route path={AppRoutes.WebstoreImporting} element={renderPage(WebstoreImporter)} />}
        <Route path={AppRoutes.UserImporting} element={renderPage(UserImporter)} />
        {!isClientStaff && <Route path={AppRoutes.ScheduledEmployeeList} element={renderPage(ScheduledEmployees)} />}
        <Route path="unauthorized" element={<UnauthorizedPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path={`/:clientId/${AppRoutes.AdminWebstorePreview}/:webstoreId`} element={CurrentLayout}>
        <Route index element={renderPage(AdminWebstorePreview)} />
        <Route path={`${AppRoutes.ReviewOrder}`} element={renderPage(ReviewOrder)} />
        <Route path={`${AppRoutes.ShoppingCart}`} element={renderPage(ShoppingCart)} />
      </Route>
      {isClientStaff && (
        <Route path={`/${AppRoutes.Webstore}/:webstoreId/:catalogGroupId`} element={<WebstoreContainer />}>
          <Route index element={renderPage(ClientWebstore)} />
        </Route>
      )}
    </Routes>
  );
};

export default AppRouters;
