import Box from '@mui/material/Box';

import useAccessControl from './useAccessControl';

export interface IAccessControlProviderProps {
  allowedRoles?: string[];
  children?: React.ReactNode;
  renderNoAccess?: () => JSX.Element;
}

export const UnAuthorized = () => <Box>You are not authorized in this function</Box>;

export const AccessControl = (props: IAccessControlProviderProps) => {
  const { allowedRoles = [], renderNoAccess = () => <UnAuthorized />, children } = props;

  const { isAuthenticated, user, isValidRole } = useAccessControl();

  if (!allowedRoles || allowedRoles.length === 0) {
    return <>{children}</>;
  }
  if (!isAuthenticated || !user) {
    return renderNoAccess();
  }

  const isPermitted = isValidRole(allowedRoles);

  if (!isPermitted) {
    return renderNoAccess();
  }

  return <>{children}</>;
};

export default AccessControl;
