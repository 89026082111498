import { appSettings } from 'app/constants/appSettings';

import axiosInstance, { axiosWithCredentialsInstance, cancelToken } from './http';
import {
  AddressesClient,
  AuthorizationClient,
  CatalogItemsClient,
  CatalogsClient,
  ClientsClient,
  DivisionsClient,
  EmployeesClient,
  IdentityUsersClient,
  ImagesClient,
  LocationsClient,
  OrdersClient,
  PaymentsClient,
  ProductsClient,
  SaleTaxesClient,
  ShoppingCartsClient,
  SkusClient,
  UsersClient,
  WebstoresClient,
} from './nswag';

export const divisionsClient = new DivisionsClient(appSettings.apiUrl, axiosInstance);
export const locationsClient = new LocationsClient(appSettings.apiUrl, axiosInstance);
export const catalogsClient = new CatalogsClient(appSettings.apiUrl, axiosInstance);
export const employeesClient = new EmployeesClient(appSettings.apiUrl, axiosInstance);
export const clientsClient = new ClientsClient(appSettings.apiUrl, axiosInstance);
export const identityClient = new IdentityUsersClient(appSettings.apiUrl, axiosInstance);
export const appUsersClient = new UsersClient(appSettings.apiUrl, axiosInstance);
export const skusClient = new SkusClient(appSettings.apiUrl, axiosInstance);
export const catalogItemsClient = new CatalogItemsClient(appSettings.apiUrl, axiosInstance);
export const ordersClient = new OrdersClient(appSettings.apiUrl, axiosInstance);
export const productsClient = new ProductsClient(appSettings.apiUrl, axiosInstance);
export const saleTaxesClient = new SaleTaxesClient(appSettings.apiUrl, axiosInstance);
export const addressesClient = new AddressesClient(appSettings.apiUrl, axiosInstance);
export const webstoresClient = new WebstoresClient(appSettings.apiUrl, axiosInstance);
export const shoppingCartsClient = new ShoppingCartsClient(appSettings.apiUrl, axiosInstance);
export const imagesClient = new ImagesClient(appSettings.apiUrl, axiosInstance);
export const paymentsClient = new PaymentsClient(appSettings.apiUrl, axiosInstance);

export const authorizationClient = new AuthorizationClient(appSettings.apiUrl, axiosWithCredentialsInstance);

export { cancelToken };
