import { User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';

export interface IUseAccessControl {
  isValidRole: (allowedRoles: string[]) => boolean;
  isAuthenticated: boolean;
  user: User | null | undefined;
  isPermitted: boolean;
}

export interface IUseAccessControlOptions {
  defaultAllowedRoles?: string[];
}

export const useAccessControl = ({ defaultAllowedRoles = [] }: IUseAccessControlOptions = {}): IUseAccessControl => {
  const auth = useAuth();

  const { isAuthenticated, user } = auth;

  const isValidRole = (allowedRoles: string[]) => {
    if (allowedRoles.length === 0) {
      return true;
    }

    const userRole = (user?.profile && (user.profile['role'] as string)) ?? '';

    return allowedRoles.includes(userRole);
  };

  return {
    isValidRole,
    isAuthenticated,
    user,
    isPermitted: isValidRole(defaultAllowedRoles),
  };
};

export default useAccessControl;
