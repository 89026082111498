import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { authorizationClient } from 'api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AppRoutes from 'app/constants/routes';

import AutoFormContainer from 'components/forms/AutoFormContainer';

import { showNotification } from 'utils/toastNotification';

import { IForgotPassword } from './types';
import { defaultForgotPassword, forgotPasswordFieldGroupsTemplate, forgotPasswordValidationSchema } from './utils';

const ForgotPassword = () => {
  const [isProcessed, setIsProcessed] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef<UseFormReturn<any, any> | null>(null);

  const onSubmit = useCallback(async (values: IForgotPassword) => {
    try {
      const forgotPasswordResult = await authorizationClient.forgotPassword(values);
      if (forgotPasswordResult) {
        setIsProcessed(true);
      } else {
        showNotification({
          message: 'Email does not exist or is incorrect, please try again',
          severity: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (err: any) {
      showNotification({
        message: err.title || 'Invalid credentials. Please try again.',
        severity: 'error',
        autoHideDuration: 3000,
      });
    }
  }, []);

  const onError = (errors: any, e: any) => console.log('', errors, e);

  useEffect(() => {
    const keydownHandler = (event: any) => {
      if (event.key === 'Enter') {
        formRef.current?.handleSubmit(onSubmit, onError)();
      }
    };
    document.addEventListener('keydown', keydownHandler);

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [onSubmit]);

  const rederResetPasswordForm = () => {
    return (
      <>
        <Typography textAlign="center" fontSize={26} fontWeight={500}>
          Reset Password
        </Typography>
        <Typography component="p" marginTop={2} textAlign="center" fontSize={16}>
          Enter your recovery email and we'll send an email with instructions to reset your password
        </Typography>
        <Box sx={{ paddingY: 3, minWidth: 1 }}>
          <AutoFormContainer
            fieldGroups={forgotPasswordFieldGroupsTemplate}
            validationSchema={forgotPasswordValidationSchema}
            item={defaultForgotPassword}
            saveIcon={<></>}
            submitButonLabel={'Send Instructions'}
            onSubmit={onSubmit}
            onError={onError}
            onCancel={() => {
              navigate(`/auth/${AppRoutes.SignIn}`);
            }}
            formRef={formRef}
          />
        </Box>
      </>
    );
  };

  const renderNotificationMessage = () => {
    return (
      <Alert severity="success" sx={{ fontSize: 16 }}>
        <AlertTitle sx={{ fontSize: 16 }}>Success</AlertTitle>
        <Box>
          If you are registered in the system you will receive an email shortly with instructions to reset your password
        </Box>
        <Box>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(`/auth/${AppRoutes.SignIn}`);
            }}
          >
            Back to login
          </Link>
        </Box>
      </Alert>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '75%',
        marginX: 'auto',
      }}
    >
      {isProcessed ? renderNotificationMessage() : rederResetPasswordForm()}
    </Box>
  );
};

export default ForgotPassword;
