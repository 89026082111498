import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';

import { useAppSelector } from 'app/hooks';

import { selectEmployeeCreditAllowance } from 'features/auth/selectors';

import { defaultAppFormatPattern } from 'utils/datetimeUtils';
import { formatMoneyNumber } from 'utils/numberUtils';

interface WebstoreShoppingCartProps {
  itemCount: number;
  onClickShoppingCart: () => void;
}

export const WebstoreShoppingCartHeader = (props: WebstoreShoppingCartProps) => {
  const { itemCount, onClickShoppingCart } = props;
  const employeeCreditAllowance = useAppSelector(selectEmployeeCreditAllowance);

  return (
    <>
      {employeeCreditAllowance && (
        <Box
          sx={{
            width: 200,
            height: 50,
            marginRight: 2,
            backgroundColor: 'background.creditBalance',
            color: 'text.creditText',
            borderRadius: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 15,
              fontWeight: '500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 1,
            }}
          >
            Credit Balance: {formatMoneyNumber(employeeCreditAllowance.balanceRemaining)}
          </Typography>
          <Typography
            sx={{
              color: 'text.availableTill',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            Available until {format(new Date(employeeCreditAllowance.effectiveDateTo), defaultAppFormatPattern)}
          </Typography>
        </Box>
      )}

      <Badge
        badgeContent={itemCount}
        invisible={!itemCount}
        color="warning"
        sx={{
          '& .MuiBadge-badge': {
            right: 32,
            top: 10,
          },
        }}
      >
        <IconButton key="shopping-cart" id="shopping-cart-button" onClick={onClickShoppingCart}>
          <ShoppingCartOutlinedIcon fontSize="medium" sx={{ color: 'black' }} />
        </IconButton>
      </Badge>
    </>
  );
};

export default WebstoreShoppingCartHeader;
