import { shoppingCartsClient } from 'api';
import { CartItemDetailViewModel, ShoppingSessionDetailViewModel } from 'api/nswag';
import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import { selectHasChanges, selectIsSaving, selectShoppingSession } from 'features/shopping-carts/selectors';
import {
  completeShoppingSession,
  getShoppingSessionByCurrentUser,
  loadShoppingSession,
  removeCartItem,
  updateCartItem,
} from 'features/shopping-carts/shoppingSessionSlice';
import { selectWebstoreId, selectWebstorePersonalization } from 'features/webstores/selectors';

export const useShoppingSession = () => {
  const dispatch = useAppDispatch();
  const shoppingSession = useAppSelector(selectShoppingSession);
  const hasChanges = useAppSelector(selectHasChanges);
  const isSaving = useAppSelector(selectIsSaving);
  const selectedWebstoreId = useAppSelector(selectWebstoreId);
  const personalization = useAppSelector(selectWebstorePersonalization);

  useEffect(() => {
    if (selectedWebstoreId && selectedWebstoreId !== shoppingSession?.webstore?.id) {
      dispatch(getShoppingSessionByCurrentUser(selectedWebstoreId));
    }
  }, [dispatch, selectedWebstoreId, shoppingSession?.webstore?.id]);

  const handleUpdateCartItem = useCallback(
    (item: CartItemDetailViewModel) => {
      dispatch(updateCartItem(item));
    },
    [dispatch]
  );

  const buildSaveRequest = useCallback(
    (
      webstoreId: number,
      cartItems: {
        partNoRef: string;
        quantity: number;
        minimumQuantity: number | undefined;
        quantityInterval: number | undefined;
      }[],
      personalization: string | undefined
    ) => {
      const request = {
        id: shoppingSession?.id ?? 0,
        webstoreId: webstoreId,
        cartItems: cartItems!.map((x) => ({
          skuNumber: x.partNoRef,
          quantity: x.quantity,
          minimumQuantity: x.minimumQuantity,
          quantityInterval: x.quantityInterval,
        })),
        personalization: personalization,
      };

      return request;
    },
    [shoppingSession?.id]
  );

  const handleLoadShoppingSession = useCallback(
    (value: ShoppingSessionDetailViewModel) => {
      dispatch(loadShoppingSession(value));
    },
    [dispatch]
  );

  const handleUpdateShoppingSession = useCallback(async () => {
    if (selectedWebstoreId) {
      const cartItems =
        shoppingSession?.cartItems?.map((x) => {
          return {
            partNoRef: x.sku?.partNoRef ?? '',
            quantity: x.quantity,
            minimumQuantity: x.minimumQuantity,
            quantityInterval: x.quantityInterval,
          };
        }) ?? [];

      const saveRequest = buildSaveRequest(selectedWebstoreId, cartItems, personalization);
      const sessionId = await shoppingCartsClient.saveShoppingSession(saveRequest);

      if (sessionId) {
        const newShoppingSession = await shoppingCartsClient.getShoppingSession(sessionId);
        handleLoadShoppingSession(newShoppingSession);
      }
    }
  }, [buildSaveRequest, handleLoadShoppingSession, selectedWebstoreId, shoppingSession?.cartItems, personalization]);

  const handleRemoveCartItem = useCallback(
    async (itemId: number) => {
      if (selectedWebstoreId && itemId) {
        const cartItems =
          shoppingSession?.cartItems
            ?.filter((x) => x.id !== itemId)
            .map((x) => {
              return {
                partNoRef: x.sku?.partNoRef ?? '',
                quantity: x.quantity,
                minimumQuantity: x.minimumQuantity,
                quantityInterval: x.quantityInterval,
              };
            }) ?? [];

        const saveRequest = buildSaveRequest(selectedWebstoreId, cartItems, personalization);
        const saveResult = await shoppingCartsClient.saveShoppingSession(saveRequest);

        if (saveResult) {
          dispatch(removeCartItem(itemId));
        } else {
          dispatch(completeShoppingSession());
        }
      }
    },
    [buildSaveRequest, dispatch, selectedWebstoreId, shoppingSession?.cartItems, personalization]
  );

  const handleCompleteShoppingSession = useCallback(() => {
    dispatch(completeShoppingSession());
  }, [dispatch]);

  return {
    shoppingSession,
    itemCount: shoppingSession?.cartItems?.length ?? 0,
    hasChanges,
    isSaving,
    selectedWebstoreId,
    updateCartItem: handleUpdateCartItem,
    removeCartItem: handleRemoveCartItem,
    loadShoppingSession: handleLoadShoppingSession,
    completeShoppingSession: handleCompleteShoppingSession,
    buildSaveRequest,
    updateShoppingSession: handleUpdateShoppingSession,
  };
};
