export const Roles = {
  Admin: 'Admin',
  Sale: 'Sale',
  ClientAdmin: 'ClientAdmin',
  ClientEmployee: 'ClientEmployee',
};

export const RoleList = [Roles.Admin, Roles.Sale, Roles.ClientAdmin, Roles.ClientEmployee];

export const SystemRoles = [Roles.Admin, Roles.Sale];
export const ClientRoles = [Roles.ClientAdmin, Roles.ClientEmployee];

export const DefaultWritableRoles = [Roles.Admin, Roles.ClientAdmin];
export const DefaultPageRoles = [Roles.Admin, Roles.Sale, Roles.ClientAdmin];

export const DisplayRoles: Record<string, string> = {
  [Roles.Admin]: 'Admin',
  [Roles.Sale]: 'Apparel Sales',
  [Roles.ClientAdmin]: 'Client Admin',
  [Roles.ClientEmployee]: 'Employee',
};

const createClientRoleNameMap = () => {
  const roleNameMap = new Map();
  roleNameMap.set(Roles.ClientAdmin.toLowerCase(), DisplayRoles[Roles.ClientAdmin]);
  roleNameMap.set(Roles.ClientEmployee.toLowerCase(), DisplayRoles[Roles.ClientEmployee]);

  return roleNameMap;
};

export const clientRoleNameMap = createClientRoleNameMap();
