import { ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

interface NavItemProps {
  isExpanded?: boolean;
  [propertyName: string]: any;
}
export const NavItem = (props: NavItemProps) => {
  const { path, icon, title, selected, isExpanded, ...others } = props;

  return (
    <ListItemButton
      component={Link}
      to={path}
      selected={selected}
      sx={{
        padding: 1,
        mx: 1,
        height: 41,
        borderRadius: 2,
        ...(!isExpanded && { mx: 0, pl: 3 }),
        color: selected ? 'text.menuItemSelected' : 'text.menuItem',
        '&:hover': {
          backgroundColor: 'action.menuItemHover',
          '&.Mui-selected': { backgroundColor: 'action.menuItemSelected' },
        },
        '&.Mui-selected': { backgroundColor: 'action.menuItemSelected' },
      }}
      {...others}
    >
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          fontSize: 14,
          fontWeight: 500,
        }}
        sx={{ opacity: isExpanded ? 1 : 0 }}
      />
    </ListItemButton>
  );
};
