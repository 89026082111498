import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { CSSObject, Theme } from '@mui/material/styles';

import { sidebarWidth } from 'app/constants/appSettings';

const openedMixin = (theme: Theme): CSSObject => ({
  width: sidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const LeftSideDrawer = (props: any) => {
  const { theme, open, isLargeScreen, children } = props;
  return (
    <Drawer
      anchor="left"
      open={open}
      variant={isLargeScreen ? 'permanent' : 'temporary'}
      PaperProps={{
        sx: {
          backgroundColor: 'background.sidebar',
          width: sidebarWidth,
          height: '90%',
          display: 'contents',
          ...(isLargeScreen ? {} : { zIndex: (theme: Theme) => theme.zIndex.appBar + 1 }),
          borderRight: 'none',
        },
      }}
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
      }}
    >
      {children}
    </Drawer>
  );
};

export const DrawerHeader = () => (
  <Box
    sx={(theme: Theme | any) => ({
      display: 'none',
      padding: theme.spacing(0, 1), // py: 0, px: 1,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    })}
  ></Box>
);

export default LeftSideDrawer;
