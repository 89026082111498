export const formatMoneyNumber = (money: number) => {
  return `$${money.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const roundNumber = (value: number, digits: number = 2) => {
  const ensuredDigits = digits ?? 2;
  const positiveExponentByDigits = `e+${ensuredDigits}`;
  const negativeExponentByDigits = `e-${ensuredDigits}`;

  return +(Math.round(Number(value + positiveExponentByDigits)) + negativeExponentByDigits);
};
