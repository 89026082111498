import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shoppingCartsClient } from 'api';
import { CartItemDetailViewModel, ShoppingSessionDetailViewModel, WebstoreDetailViewModel } from 'api/nswag';

export interface IShoppingSessionState {
  shoppingSession?: ShoppingSessionDetailViewModel | null;
  selectedWebstore: WebstoreDetailViewModel | null;
  isSaving?: boolean;
  error?: any;
  hasChanges?: boolean;
  hasLoaded?: boolean;
}

const initialState: IShoppingSessionState = {
  shoppingSession: null,
  selectedWebstore: null,
  isSaving: false,
  error: null,
  hasChanges: false,
  hasLoaded: false,
};

export const getShoppingSessionByCurrentUser = createAsyncThunk(
  'shoppingSession/getByCurrentUser',
  async (webstoreId: number) => {
    if (!webstoreId) {
      return null;
    }

    const response = await shoppingCartsClient.getUserShoppingSession(webstoreId);
    return response;
  }
);

export const getShoppingSession = createAsyncThunk('shoppingSession/getById', async (shoppingSessionId: number) => {
  const response = await shoppingCartsClient.getShoppingSession(shoppingSessionId);
  return response;
}); // deprecated?

export const shoppingSessionSlice = createSlice({
  name: 'shoppingSession',
  initialState,
  reducers: {
    loadShoppingSession: (state, action: PayloadAction<ShoppingSessionDetailViewModel>) => {
      state.shoppingSession = action.payload;
      state.isSaving = false;
    },
    updateCartItem: (state, action: PayloadAction<CartItemDetailViewModel>) => {
      const { payload } = action;
      const itemIndex = state.shoppingSession?.cartItems?.findIndex((x) => x.id === payload.id) ?? -1;

      if (itemIndex > -1) {
        payload.total = payload.quantity * payload.unitPrice;
        state.shoppingSession!.cartItems![itemIndex] = payload;

        state.hasChanges = true;
      }
    },
    removeCartItem: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const itemIndex = state.shoppingSession?.cartItems?.findIndex((x) => x.id === payload) ?? -1;

      if (itemIndex > -1) {
        state.shoppingSession!.cartItems?.splice(itemIndex, 1);
        state.hasChanges = true;
      }
    },
    completeShoppingSession: (state) => {
      state.shoppingSession = null;
      state.isSaving = false;
      state.hasChanges = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShoppingSessionByCurrentUser.fulfilled, (state, action) => {
        state.shoppingSession = action.payload;
        state.hasLoaded = true;
      })
      .addCase(getShoppingSession.fulfilled, (state, action) => {
        state.shoppingSession = action.payload;
      });
  },
});

export const { updateCartItem, removeCartItem, completeShoppingSession, loadShoppingSession } =
  shoppingSessionSlice.actions;

const shoppingSessionSliceReducer = shoppingSessionSlice.reducer;
export default shoppingSessionSliceReducer;
