import * as Yup from 'yup';

import { IFieldGroupTemplate } from 'types/FormGenerator';

export const setPasswordFieldGroups: IFieldGroupTemplate[] = [
  {
    fields: [
      { name: 'password', type: 'password', label: 'Password', required: true, autoComplete: 'new-password' },
      {
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password',
        required: true,
        autoComplete: 'new-password',
      },
    ],
  },
];

export const validatePassword = (message: string) =>
  Yup.string()
    .required(message)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-])(?=.{6,})/,
      'The password must be at least 6 characters and contain upper and lower case letters, numbers, symbols.'
    );

export const setPasswordValidationSchema = Yup.object({
  password: validatePassword('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password and Confirm Password does not match.'),
});
