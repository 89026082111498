export const appSettings = {
  apiUrl: process.env.REACT_APP_API_URL,
};

export const sidebarWidth = 260;

export const defaultPageSizeOptions = [10, 20, 50];

const signInUrl = `${window.location.origin}/auth/signin`;

export const oidcConfig = {
  authority: appSettings.apiUrl ?? window.location.origin,
  client_id: 'adminPortalApi',
  client_secret: '388D45FA-B36B-4988-BA59-B187D329C207',
  redirect_uri: signInUrl,
  silent_redirect_uri: signInUrl,
  post_logout_redirect_uri: `${window.location.origin}/auth/signout`,
  automaticSilentRenew: true,
  response_type: 'code',
  scope: 'openid profile roles offline_access portalApi',
  renewTimeBeforeTokenExpiresInSeconds: 20,
  useRefreshToken: true,
};

export const standardUsDateFormat = 'MM/dd/yy';
