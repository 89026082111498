import { TypographyOptions } from '@mui/material/styles/createTypography';

export default function themeTypography(): TypographyOptions {
  return {
    fontFamily: `"SF Pro Display", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    h6: {
      fontWeight: 500,
      fontSize: '0.75rem',
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h1: {
      fontSize: '2.125rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.334em',
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    button: {
      textTransform: 'none',
    },
  };
}
