import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';

import { useAppSelector } from 'app/hooks';
import { MenuItem } from 'app/navigationMenuItems';

import {
  selectIsLoadingWebstoreCatalogs,
  selectWebstoreActive,
  selectWebstoreCatalogMenus,
} from 'features/webstores/selectors';

import { NavItem } from './NavItem';

interface WebstoreMenuListProps {
  currentPath: string;
  showDivider: boolean;
}

export const WebstoreMenuList = (props: WebstoreMenuListProps) => {
  const { currentPath, showDivider } = props;

  const webstoreCatalogMenus = useAppSelector(selectWebstoreCatalogMenus);
  const isWebstoreActive = useAppSelector(selectWebstoreActive);
  const isLoadingWebstoreCatalogs = useAppSelector(selectIsLoadingWebstoreCatalogs);

  const catalogGroups = webstoreCatalogMenus ?? [];

  return (
    <>
      {isLoadingWebstoreCatalogs && (
        <LoadingButton size="small" fullWidth loading={true} variant="text" loadingPosition="start" startIcon={<></>}>
          Loading Catalogs…
        </LoadingButton>
      )}
      {isWebstoreActive && showDivider && catalogGroups.length > 0 && <Divider sx={{ my: 1 }} />}
      {isWebstoreActive &&
        catalogGroups.map((item: MenuItem) => (
          <NavItem
            key={item.title}
            path={item.path}
            title={item.title}
            isExpanded={true}
            selected={item.path === currentPath}
          />
        ))}
    </>
  );
};

export default WebstoreMenuList;
