import SvgIcon from '@mui/material/SvgIcon';

export const ErrorWaterMark = () => {
  return (
    <SvgIcon sx={{ width: '225.000000pt', height: '225.000000pt' }} viewBox="0 0 225.000000 225.000000">
      <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#FF0000" stroke="none">
        <path
          d="M761 2014 c-255 -69 -440 -312 -441 -581 l0 -62 -59 -32 c-159 -84
-252 -242 -252 -426 0 -98 19 -170 67 -249 45 -75 74 -106 148 -155 108 -72
149 -79 472 -79 l284 0 0 65 0 65 -272 0 c-306 0 -340 6 -425 69 -105 80 -149
176 -141 310 9 157 120 286 281 325 l27 6 0 99 c0 179 38 288 135 390 98 103
215 148 361 139 136 -9 244 -67 328 -175 36 -45 44 -51 66 -45 14 4 63 7 110
7 72 0 93 -4 140 -27 65 -32 134 -97 162 -153 11 -22 21 -41 22 -42 2 -2 30 6
64 18 l62 21 -15 34 c-41 99 -133 191 -243 242 -67 30 -90 36 -174 40 l-96 5
-57 52 c-111 104 -247 155 -405 154 -52 0 -119 -7 -149 -15z"
        />
        <path
          d="M1556 1314 c-248 -60 -425 -287 -426 -545 0 -84 35 -209 81 -284 68
-113 195 -209 328 -249 85 -25 223 -21 318 9 181 57 321 204 368 384 20 77 20
217 1 286 -83 296 -376 470 -670 399z m223 -125 c127 -29 238 -119 294 -236
30 -64 32 -75 32 -178 0 -103 -2 -114 -32 -177 -44 -94 -109 -160 -202 -206
-73 -36 -78 -37 -186 -37 -105 0 -114 2 -178 33 -270 133 -328 487 -112 691
108 102 247 142 384 110z"
        />
        <path
          d="M1510 945 l-44 -45 64 -65 64 -65 -62 -63 -62 -63 45 -44 45 -44 63
62 63 62 63 -64 63 -63 47 46 48 46 -63 65 -64 65 62 63 62 62 -44 45 -44 45
-63 -62 -63 -62 -63 62 c-34 34 -65 62 -68 62 -3 0 -25 -20 -49 -45z"
        />
      </g>
    </SvgIcon>
  );
};
export default ErrorWaterMark;
