import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {            
    // Redirect to home and prevent cache hits with a unique key
    console.log('signout')
    navigate(`/?cb=${new Date().getTime()}`, { replace: true });
  }, []);

  return null;
};

export default SignOut;
