import { RootState } from 'app/store';

export const selectWebstoreLogoId = (state: RootState) => state.webstores.webstore?.logoStorageId;

export const selectWebstoreActive = (state: RootState) => state.webstores.webstore?.isActive;

export const selectWebstoreItem = (state: RootState) => state.webstores.webstore;

export const selectWebstoreCatalogMenus = (state: RootState) => state.webstores.webstoreCatalogMenus;

export const selectWebstoreId = (state: RootState) => state.webstores.webstore?.id;

export const selectIsLoadingWebstoreCatalogs = (state: RootState) => state.webstores.isLoadingWebstoreCatalogs;

export const selectWebstoreCatalogGroups = (state: RootState) => state.webstores.catalogGroups;

export const selectWebstorePersonalization = (state: RootState) => state.webstores.personalization;
