import Box from '@mui/material/Box';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useNavigate } from 'react-router-dom';

import ErrorBoundary from 'app/ErrorBoundary';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { getUserInfo } from 'features/auth/authSessionSlice';
import { selectCurrentUser } from 'features/auth/selectors';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import SideBar from './sidebar';

export const AppContainer = () => {
  const auth = useAuth();
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleGetAuthenticatedUser = async () => {
      if (auth.isAuthenticated && !auth.isLoading && !currentUser) {
        dispatch(getUserInfo());
      }

      if (!auth.isAuthenticated && !auth.isLoading) {
        navigate('/auth');
      }
    };
    handleGetAuthenticatedUser();
  }, [auth, currentUser, dispatch, navigate]);

  return (
    <Box sx={{ display: 'flex' }}>
      <SideBar />
      <Box sx={{ flexGrow: 1 }}>
        <AppHeader />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pl: 1,
            height: 'auto',
            marginBottom: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              p: 1,
              m: 0,
            }}
          >
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>

      <AppFooter />
    </Box>
  );
};
