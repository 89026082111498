import { useAppDispatch } from 'app/hooks';

import { ConfirmDialog, InfoDialog } from 'components/dialogs';

import { closeDialog, openDialog } from 'features/dialog';

const useDialog = () => {
  const dispatch = useAppDispatch();

  const show = (DialogComponent: any, props: any) => {
    return new Promise((resolve) => {
      const id = new Date().getTime();
      const newProps = { ...(props || {}) };

      newProps.onDialogClose = (modalResult: any) => {
        dispatch(closeDialog(id));
        resolve(modalResult);
      };

      dispatch(
        openDialog({
          id,
          component: DialogComponent,
          props: newProps,
        })
      );
    });
  };

  const message = async (props: any) => {
    const dialog = await show(ConfirmDialog, props);
    return dialog;
  };
  const info = async (props: any) => {
    const dialog = await show(InfoDialog, props);
    return dialog;
  };

  return {
    show,
    message,
    info,
  };
};

export default useDialog;
