import { publish, subscribe, unsubscribe } from './event-emitter';

export type NotificationSeverity = 'success' | 'info' | 'warning' | 'error';

export interface IToastMessage {
  id?: number;
  message: string;
  severity?: NotificationSeverity;
  autoHideDuration?: number;
}

export const toastEventName = {
  open: 'toast.open',
  close: 'toast.close',
};

export const showNotification = (messagePayload: IToastMessage) => {
  publish(toastEventName.open, { id: new Date().getTime(), ...messagePayload });
};

export const subscribeNotification = (componentName: string, callback: Function) => {
  subscribe(componentName, toastEventName.open, callback);
};

export const ubSubscribeNotification = (componentName: string) => {
  unsubscribe(componentName);
};
