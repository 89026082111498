import { Box, List, Typography, useTheme } from '@mui/material';
import ApparelLogo from 'assets/images/app-logo.png';
import produce from 'immer';
import { useEffect } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { ClientRoles } from 'app/constants/roles';
import AppRoutes from 'app/constants/routes';
import { useAppSelector } from 'app/hooks';
import { MenuItem, navigationMenuItems } from 'app/navigationMenuItems';

import useAccessControl from 'features/auth/access-control/useAccessControl';
import { selectCurrentUser } from 'features/auth/selectors';
import { selectWebstoreCatalogMenus } from 'features/webstores/selectors';

import { getServerImageUrl } from 'utils/imageUtils';

import LeftSideDrawer from './LeftSideDrawer';
import { NavItem } from './NavItem';
import WebstoreMenuList from './WebstoreMenuList';

interface IProps {
  logo?: string;
}

export const SideBar = (props: IProps) => {
  const { logo } = props;
  const theme = useTheme();
  const { pathname: currentPath } = useLocation();
  const saleSummaryPathMatch = useMatch(`/${AppRoutes.SalesSummary}/*`);
  const navigate = useNavigate();
  const webstoreCatalogMenus = useAppSelector(selectWebstoreCatalogMenus);
  const currentUser = useAppSelector(selectCurrentUser);

  const { isValidRole, isAuthenticated } = useAccessControl();
  const isClientUser = isValidRole(ClientRoles);

  const visibleGroupMenus = produce(navigationMenuItems, (draftGroups) => {
    for (let i = 0; i < draftGroups.length; i++) {
      const group = draftGroups[i];

      const visibleItems = group.items.filter((item: MenuItem) =>
        item.allowedRoles ? isValidRole(item.allowedRoles) : isValidRole(group.allowedRoles ?? [])
      );

      group.items = visibleItems;
    }
  }).filter((group) => group.items.length > 0);

  useEffect(() => {
    if (!isAuthenticated || !currentUser) {
      return;
    }

    if (currentPath && currentPath === '/') {
      if (isClientUser) {
        if (!webstoreCatalogMenus) {
          return;
        }

        if ((webstoreCatalogMenus ?? []).length > 0) {
          navigate(webstoreCatalogMenus![0].path);
        } else {
          navigate(`/${AppRoutes.UserDetail}/${currentUser!.id}`);
        }
      } else {
        navigate(AppRoutes.SalesSummary);
      }
    }
  }, [currentPath, currentUser, isAuthenticated, isClientUser, navigate, webstoreCatalogMenus]);

  const imgSrc = isClientUser ? getServerImageUrl(logo, '/Images/default-logo.jpg') : ApparelLogo;

  return (
    <Box sx={{ padding: 1 }}>
      <LeftSideDrawer isLargeScreen={true} theme={theme} open={true}>
        <Box
          component="img"
          src={imgSrc}
          alt="Logo"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            padding: 3,
            '&:hover': { cursor: 'pointer' },
          }}
          onClick={() => navigate('/')}
        />

        <Box sx={{ padding: 1 }}>
          {visibleGroupMenus.map((group) => (
            <List
              key={group.id}
              sx={{
                flexGrow: 1,
                mt: 1,
              }}
              subheader={
                group.title && (
                  <Typography
                    variant="subtitle2"
                    display="block"
                    sx={{ mx: 2, fontWeight: 500, color: 'text.title' }}
                    gutterBottom
                  >
                    {group.title}
                  </Typography>
                )
              }
            >
              {group.items.map((item: MenuItem) => (
                <NavItem
                  key={item.title}
                  icon={item.icon}
                  path={item.path}
                  title={item.title}
                  isExpanded={true}
                  selected={item.path === currentPath || item.path === saleSummaryPathMatch?.pathnameBase}
                />
              ))}
            </List>
          ))}
          {isClientUser && <WebstoreMenuList currentPath={currentPath} showDivider={visibleGroupMenus.length > 0} />}
        </Box>
      </LeftSideDrawer>
    </Box>
  );
};

export default SideBar;
