import { configureStore } from '@reduxjs/toolkit';

import authSessionReducer from 'features/auth/authSessionSlice';
import dialogReducer from 'features/dialog/dialogSlice';
import shoppingSessionReducer from 'features/shopping-carts/shoppingSessionSlice';
import webstoresReducer from 'features/webstores/webstoresSlice';

export const store = configureStore({
  reducer: {
    dialog: dialogReducer,
    authSession: authSessionReducer,
    shoppingSession: shoppingSessionReducer,
    webstores: webstoresReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['dialog/openDialog'],
        ignoredPaths: ['dialog'],
      },
    }),
  preloadedState: {},
  devTools: { latency: 0 },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
