import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import ErrorWaterMark from 'components/loaders/ErrorWaterMark';

interface IState {
  hasError: boolean;
}

interface IProps {
  children: React.ReactNode;
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log('', { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            pl: 1,
            minHeight: '100vh',
          }}
        >
          <ErrorWaterMark />
          <Alert severity="error">
            <AlertTitle sx={{ fontSize: 20 }}>Something went wrong!!!</AlertTitle>
            <Typography variant="body1" gutterBottom>
              Please contact the system administrator or try refreshing the page
            </Typography>
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}
