import { Box, Typography } from '@mui/material';
import { authorizationClient } from 'api';
import { ResetPasswordCommand } from 'api/nswag';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import AppRoutes from 'app/constants/routes';

import AutoFormContainer from 'components/forms/AutoFormContainer';

import { setPasswordValidationSchema } from 'features/users/set-password/utils';

import { showNotification } from 'utils/toastNotification';

import { IResetPassword, IResetPasswordState } from './types';
import { defaultResetPassword, resetPasswordFieldGroupsTemplate } from './utils';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState('');
  const formRef = useRef<UseFormReturn<any, any> | null>(null);

  const onSubmit = useCallback(() => {
    return async (values: IResetPassword) => {
      try {
        const request: ResetPasswordCommand = {
          newPassword: values.password,
          token,
          userId,
        };
        const resetPasswordResult = await authorizationClient.resetPassword(request);
        if (resetPasswordResult) {
          showNotification({
            message: 'Reset password successful',
            severity: 'success',
            autoHideDuration: 3000,
          });
          navigate(`/auth/${AppRoutes.SignIn}`);
          return;
        }
        showNotification({
          message: 'incorrect, please try again',
          severity: 'error',
          autoHideDuration: 3000,
        });
      } catch (error: any) {
        showNotification({
          message: error?.title || 'Invalid credentials. Please try again.',
          severity: 'error',
          autoHideDuration: 3000,
        });
      }
    };
  }, [navigate, token, userId]);
  
  const onError = (errors: any, e: any) => console.log('', errors, e);

  useEffect(() => {
    const keydownHandler = (event: any) => {
      if (event.key === 'Enter') {
        formRef.current?.handleSubmit(onSubmit(), onError)();
      }
    };
    document.addEventListener('keydown', keydownHandler);

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [onSubmit]);

  useEffect(() => {
    const tokenQuery = searchParams.get('token') || '';
    const userIdQuery = searchParams.get('userId') || '';
    if (tokenQuery && userIdQuery) {
      navigate(`/auth/${AppRoutes.ResetPassword}`, {
        replace: true,
        state: {
          token: tokenQuery,
          userId: userIdQuery,
        },
      });
    } else {
      setToken((state as IResetPasswordState).token);
      setUserId((state as IResetPasswordState).userId);
    }
  }, [navigate, searchParams, state]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '75%',
        marginX: 'auto',
      }}
    >
      <Typography textAlign="center" fontSize={26} fontWeight={500}>
        Create new password
      </Typography>

      <Box sx={{ paddingY: 3, minWidth: 1 }}>
        <AutoFormContainer
          fieldGroups={resetPasswordFieldGroupsTemplate}
          validationSchema={setPasswordValidationSchema}
          item={defaultResetPassword}
          saveIcon={<></>}
          submitButonLabel={'Change Password'}
          onSubmit={onSubmit()}
          onError={onError}
          onCancel={() => {
            navigate(`/auth/${AppRoutes.SignIn}`);
          }}
          formRef={formRef}
        />
      </Box>
    </Box>
  );
};

export default ResetPassword;
