import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { RoleList, Roles } from 'app/constants/roles';
import AppRoutes from 'app/constants/routes';
import { useAppSelector } from 'app/hooks';
import { PageContainer } from 'app/page-layout';

import { selectCurrentUser } from 'features/auth/selectors';

import { TabTypes } from './types';

const SaleSummaryContainer = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  const [currentTab, setCurrentTab] = useState<TabTypes>('order-history');
  const navigate = useNavigate();

  useEffect(() => {
    if (currentTab === AppRoutes.OrderHistoryList) {
      navigate(`/${AppRoutes.SalesSummary}/${AppRoutes.OrderHistoryList}`);
    }

    if (currentTab === AppRoutes.TopProductList && currentUser?.role !== Roles.ClientEmployee) {
      navigate(`/${AppRoutes.SalesSummary}/${AppRoutes.TopProductList}`);
    }
  }, [currentUser?.role, currentTab, navigate]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabTypes) => {
    setCurrentTab(newValue);
  };

  return (
    <PageContainer allowedRoles={RoleList}>
      <Box>
        <Tabs value={currentTab} onChange={handleChangeTab}>
          <Tab value={AppRoutes.OrderHistoryList} label="Order History" />
          {currentUser?.role !== Roles.ClientEmployee && <Tab value={AppRoutes.TopProductList} label="Top Products" />}
        </Tabs>
      </Box>

      <Box paddingTop={3}>
        <Outlet />
      </Box>
    </PageContainer>
  );
};

export default SaleSummaryContainer;
