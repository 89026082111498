import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoginImage from 'assets/images/app-login.jpg';
import ApparelLogo from 'assets/images/app-logo.png';
import { Outlet } from 'react-router-dom';

export const AuthLayout = () => {
  return (
    <Container maxWidth={false} disableGutters={true}>
      <Box display="flex" alignItems="stretch" minHeight="100vh">
        <Box sx={{ flexBasis: '50%', flexGrow: 1 }}>
          <Box
            sx={{
              height: '100%',
              background: `url(${LoginImage}) no-repeat center`,
              backgroundSize: '100% 100%',
            }}
          />
        </Box>
        <Box
          sx={{
            marginTop: '6%',
            padding: 3,
            flexBasis: '50%',
            flexGrow: 1,
          }}
        >
          <Box display="flex" justifyContent="center" padding={2} marginY={2}>
            <Box
              component="img"
              src={ApparelLogo}
              alt="Apparel Logo"
              sx={{
                objectFit: 'contain',
              }}
            />
          </Box>

          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};

export default AuthLayout;
