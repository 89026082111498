import { Roles } from './constants/roles';
import AppRoutes from './constants/routes';

export const navigationMenuItems: MenuGroupItem[] = [
  {
    id: 'default',
    title: '',
    type: 'group',
    allowedRoles: [Roles.Admin, Roles.Sale, Roles.ClientAdmin],
    // allowedRoles: [], enable for testing
    items: [
      {
        path: `/${AppRoutes.SalesSummary}`,
        icon: <></>,
        title: 'Order History',
        allowedRoles: [Roles.Admin, Roles.Sale],
      },
      {
        path: `/${AppRoutes.ClientList}`,
        icon: <></>,
        title: 'Clients',
        allowedRoles: [Roles.Admin, Roles.Sale],
      },
      {
        path: `/${AppRoutes.ClientDetail}`,
        icon: <></>,
        title: 'Client Detail',
        allowedRoles: [Roles.ClientAdmin],
      },
      {
        path: `/${AppRoutes.UserList}`,
        icon: <></>,
        title: 'Users',
        allowedRoles: [Roles.Admin, Roles.Sale, Roles.ClientAdmin],
      },
      {
        path: `/${AppRoutes.CatalogList}`,
        icon: <></>,
        title: 'Catalogs',
        allowedRoles: [Roles.Admin, Roles.Sale],
      },
      {
        path: `/${AppRoutes.WebstoreImporting}`,
        icon: <></>,
        title: 'Import Webstore',
        allowedRoles: [Roles.Admin],
      },
      {
        path: `/${AppRoutes.UserImporting}`,
        icon: <></>,
        title: 'Import Users',
        allowedRoles: [Roles.Admin],
      },
    ],
  },
];

export interface MenuGroupItem {
  id: string;
  type: string;
  allowedRoles: string[] | null | undefined;
  title: string;
  items: MenuItem[];
}

export interface MenuItem {
  path: string;
  icon?: JSX.Element;
  title: string;
  allowedRoles?: string[] | null | undefined;
}
