import Box from '@mui/material/Box';
import { webstoresClient } from 'api';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useNavigate } from 'react-router-dom';

import ErrorBoundary from 'app/ErrorBoundary';
import AppRoutes from 'app/constants/routes';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import useDialog from 'hooks/useDialog';

import useAccessControl from 'features/auth/access-control/useAccessControl';
import { getEmployeeCreditAllowance, getUserInfo } from 'features/auth/authSessionSlice';
import { selectCurrentUser } from 'features/auth/selectors';
import { useShoppingSession } from 'features/shopping-carts/useShoppingSession';
import { selectWebstoreItem } from 'features/webstores/selectors';
import { getWebstoreByCurrentUser, getWebstoreCatalogs } from 'features/webstores/webstoresSlice';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import WebstoreShoppingCartHeader from './components/WebstoreShoppingCartHeader';
import SideBar from './sidebar';
import { Typography } from '@mui/material';

// The layout for client users (Client Admin, Employee)
export const WebstoreContainer = () => {
  const auth = useAuth();
  const currentUser = useAppSelector(selectCurrentUser);
  const webstore = useAppSelector(selectWebstoreItem);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isWebstoreReloading, setIsWebstoreReloading] = useState(false);
  const dialog = useDialog();

  const { isAuthenticated } = useAccessControl();
  const { itemCount, completeShoppingSession } = useShoppingSession();
  const isReadyToLoadAuthenticatedData = auth.isAuthenticated && !auth.isLoading;

  useEffect(() => {
    const handleGetAuthenticatedUser = async () => {
      if (isReadyToLoadAuthenticatedData && !currentUser) {
        dispatch(getUserInfo());
        dispatch(getEmployeeCreditAllowance());
      }

      if (!auth.isAuthenticated && !auth.isLoading) {
        navigate(`/${AppRoutes.Auth}`);
      }
    };
    handleGetAuthenticatedUser();
  }, [auth, currentUser, dispatch, isReadyToLoadAuthenticatedData, navigate]);

  useEffect(() => {
    dispatch(getWebstoreByCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getWebstoreCatalogs());
    }
  }, [dispatch, isAuthenticated]);

  // Request the webstore's settings every 5 min and force refreshing if some changes:
  useEffect(() => {
    if (isWebstoreReloading) {
      const confirmAndReload = async () => {
        await dialog.message({
          title: 'Confirmation',
          message: 'The webstore settings were changed, you must refresh the page to get the new update.',
          btnOKLabel: 'OK',
          btnCancelLabel: null,
        });
        window.location.reload();
      };

      confirmAndReload();
    }
  }, [dialog, isWebstoreReloading]);

  useEffect(() => {
    const timer = setInterval(async () => {
      if (isWebstoreReloading) {
        return;
      }

      const webstoreSettings = await webstoresClient.getWebstoreByCurrentUser();
      if (webstoreSettings.isActive !== webstore?.isActive) {
        setIsWebstoreReloading(true);
      }
    }, 60000 * 5); // interval in 5 min.

    return () => {
      clearInterval(timer);
    };
  }, [isWebstoreReloading, webstore]);

  useEffect(() => {
    return () => {
      completeShoppingSession();
    };
  }, [completeShoppingSession, dispatch]);

  const handleClickShoppingCart = () => {
    if (webstore?.id) {
      navigate(`/${webstore.client!.id}/${AppRoutes.AdminWebstorePreview}/${webstore.id}/${AppRoutes.ShoppingCart}`);
    }
  };

  const webstoreHeader =
    webstore?.id && webstore.isActive ? (
      <WebstoreShoppingCartHeader itemCount={itemCount} onClickShoppingCart={handleClickShoppingCart} />
    ) : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <SideBar logo={webstore?.logoStorageId} />
      <Box sx={{ flexGrow: 1 }}>
        <AppHeader webstoreHeader={webstoreHeader} showOrderHistory={true} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pl: 1,
            height: 'auto',
            marginBottom: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              p: 1,
              m: 0,
            }}
          >
            <ErrorBoundary>
            {webstore?.id && webstore.isActive && (<Outlet />)}
            {webstore?.id && !webstore.isActive && (<Typography>No active webstore. Please contact an administrator</Typography>)}
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>

      <AppFooter />
    </Box>
  );
};
