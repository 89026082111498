import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { appUsersClient, employeesClient } from 'api';
import { AllowanceDetailViewModel, UserListViewModel } from 'api/nswag';

export interface IAuthSessionState {
  userInfo: UserListViewModel | null;
  employeeAllowance?: AllowanceDetailViewModel | null;
}

const initialState: IAuthSessionState = {
  userInfo: null,
  employeeAllowance: null,
};

export const getUserInfo = createAsyncThunk('auth/getUserInfo', async () => {
  const response = await appUsersClient.getCurrentUserInfo();
  return response;
});

export const getEmployeeCreditAllowance = createAsyncThunk('auth/getEmployeeCreditAllowance', async () => {
  const response = await employeesClient.getCreditAppliedByCurrentUser();
  return response;
});

export const authSessionSlice = createSlice({
  name: 'authSession',
  initialState,
  reducers: {
    userSignout: (state: IAuthSessionState, action: any) => {
      state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(getEmployeeCreditAllowance.fulfilled, (state, action) => {
      state.employeeAllowance = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { userSignout } = authSessionSlice.actions;

export default authSessionSlice.reducer;
