import axios, { AxiosRequestConfig } from 'axios';
import { User } from 'oidc-client-ts';

import { oidcConfig } from 'app/constants/appSettings';
import { systemErrorCodes } from 'app/constants/errorCodes';

import { showNotification } from 'utils/toastNotification';

export const statusCodes = {
  status503ServiceUnavailable: 503,
  status401Unauthorized: 401,
  status403Forbidden: 403,
  status500InternalError: 500,
};

const axiosOptionsBase = {
  timeout: 180000,
  headers: {
    'content-type': 'application/json',
  },
  transformResponse: [],
};

const axiosInstance = axios.create({ ...axiosOptionsBase });

export const createAxiosInstance = (options: AxiosRequestConfig<any>) =>
  axios.create({
    ...axiosOptionsBase,
    ...options,
  });

export const axiosWithCredentialsInstance = axios.create({
  ...axiosOptionsBase,
  withCredentials: true,
});

function getUser() {
  const oidcStorage = localStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

axiosInstance.interceptors.request.use(
  (request) => {
    const user = getUser();

    if (user && request.headers) {
      request.headers.Authorization = `${user.token_type} ${user.access_token}`;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }

    console.log('error: ', error);

    if (axios.isAxiosError(error) && error.code === 'ECONNABORTED') {
      showNotification({
        message: 'Request time-out',
        severity: 'error',
        autoHideDuration: 5000,
      });

      return Promise.reject(error);
    }

    const response = error.response;

    if (
      error.request.responseType === 'blob' &&
      response.data instanceof Blob &&
      response.data.type &&
      response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          response.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(response.data);
      });
    }

    if (!axios.isAxiosError(error)) {
      const status = response.status;
      const responseDataObject = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
      if (status === statusCodes.status401Unauthorized || status === statusCodes.status403Forbidden) {
        showNotification({
          message: 'You are not authorized to this function',
          severity: 'error',
          autoHideDuration: 3000,
        });
      } else if (
        status === statusCodes.status503ServiceUnavailable &&
        responseDataObject.code === systemErrorCodes.unavailableWebstore
      ) {
        showNotification({
          message: 'The webstore has been inactive, please contact your administrator. ',
          severity: 'error',
          autoHideDuration: 5000,
        });
        window.setTimeout(() => {
          window.location.href = '/';
        }, 6000);
      } else if (status === statusCodes.status500InternalError) {
        showNotification({
          message: 'An unexpected error occurred',
          severity: 'error',
          autoHideDuration: 5000,
        });
      } else {
        showNotification({
          message: responseDataObject?.detail || responseDataObject?.title || 'An unexpected error occurred',
          severity: 'error',
          autoHideDuration: 5000,
        });
      }
    }

    return Promise.reject(error);
  }
);

export const cancelToken = axios.CancelToken;

export default axiosInstance;
