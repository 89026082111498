import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import AppRoutes from 'app/constants/routes';
import { useAppSelector } from 'app/hooks';

import { selectCurrentUser } from 'features/auth/selectors';

interface IProps {
  webstoreHeader?: React.ReactNode;
  showOrderHistory?: boolean;
  [key: string]: any;
}

export const AppHeader = (props: IProps) => {
  const { webstoreHeader, showOrderHistory } = props;
  const [anchorUserMenu, setAnchorUserMenu] = React.useState<null | HTMLElement>(null);

  const currentUser = useAppSelector(selectCurrentUser);

  const navigate = useNavigate();
  const auth = useAuth();
  const openUserMenu = Boolean(anchorUserMenu);

  const handleClickAvatar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorUserMenu(null);
  };

  return (
    <AppBar position="relative">
      <Toolbar
        disableGutters
        sx={{
          px: 2,
          minHeight: 64,
          alignItems: 'center',
          alignContent: 'space-between',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            minHeight: 10,
          }}
        />

        {auth.isAuthenticated && currentUser && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {webstoreHeader}
            <IconButton
              onClick={handleClickAvatar}
              id="avatar-button"
              aria-controls={openUserMenu ? 'user-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openUserMenu ? 'true' : undefined}
              disableRipple={true}
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: '#303030',
              }}
            >
              <AccountCircleIcon sx={{ color: 'black', marginRight: 1 }} />
              {`${currentUser?.firstName} ${currentUser?.lastName}`.trim()}
            </IconButton>

            <Menu
              id="user-menu"
              anchorEl={anchorUserMenu}
              open={openUserMenu}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'avatar-button',
                sx: { minWidth: 130 },
              }}
            >
              {currentUser.id && (
                <MenuItem
                  onClick={() => {
                    navigate(`/${AppRoutes.UserDetail}/${currentUser.id}`);
                    handleClose();
                  }}
                >
                  Profile
                </MenuItem>
              )}

              {showOrderHistory && (
                <MenuItem
                  disableRipple
                  onClick={() => {
                    navigate(`/${AppRoutes.SalesSummary}`);
                    handleClose();
                  }}
                >
                  Order History
                </MenuItem>
              )}

              <MenuItem
                onClick={async () => {
                  await auth.removeUser();                                    
                  navigate(`/?cb=${new Date().getTime()}`, { replace: true });
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
