const AppRoutes = {
  Auth: 'auth',
  SalesSummary: 'sales-summary',
  OrderHistoryList: 'order-history',
  TopProductList: 'top-products',
  DialogExamples: 'dialog-examples',
  FormExamples: 'form-examples',
  DataListExamples: 'data-list-examples',
  SignIn: 'signin',
  SignOut: 'signout',
  SetPassWord: 'set-password',
  ForgotPassword: 'forgot-password',
  DivisionList: 'divisions',
  DivisionDetail: 'division-detail',
  LocationList: 'locations',
  LocationDetail: 'location-detail',
  ClientList: 'clients',
  ClientDetail: 'client-detail',
  UserList: 'users',
  UserDetail: 'user-detail',
  CatalogList: 'catalogs',
  OrderDetail: 'order-detail',
  AdminWebstorePreview: 'webstore-preview',
  Webstore: 'webstore',
  ShoppingCart: 'shopping-cart',
  ResetPassword: 'reset-password',
  ReviewOrder: 'review-order',
  Unauthorized: 'unauthorized',
  UserImporting: 'user-importer',
  PaymentRedirect: 'payment-redirect',
  WebstoreImporting: 'webstore-importer',
  ScheduledEmployeeList: 'scheduled-employees',
};
export default AppRoutes;
