import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

interface IProps {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  hasXButton?: boolean;
  show: boolean;
  onCancel?: Function;
  onDialogClose: Function;
  onSave?: Function;
  saveButtonProps?: Record<string, any>;
  cancelButtonProps?: Record<string, any>;
  hideBottomActions?: boolean;
  dialogProps?: DialogProps;
}

const SaveCancelDialog = ({
  children,
  title,
  hasXButton,
  show,
  onCancel,
  onDialogClose,
  onSave,
  saveButtonProps = {},
  cancelButtonProps = {},
  hideBottomActions,
  dialogProps,
}: IProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const handleClose = () => {
    onDialogClose({
      success: false,
    });
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      onDialogClose({
        success: false,
      });
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    if (onSave) {
      await onSave();
    }
    setIsSaving(false);
  };

  return (
    <Dialog open={show} onClose={handleClose} {...dialogProps}>
      {(title !== undefined || hasXButton) && (
        <DialogTitle sx={{ fontSize: '1.25rem' }}>
          {title}
          {hasXButton && (
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      {!hideBottomActions ? (
        <DialogActions sx={{ pr: 3, pb: 3 }}>
          {onSave && (
            <LoadingButton
              disabled={isSaving || saveButtonProps.disabled}
              loading={isSaving}
              loadingPosition="end"
              endIcon={<></>}
              variant="contained"
              onClick={handleSave}
            >
              {saveButtonProps.label ? saveButtonProps.label : 'Save'}
            </LoadingButton>
          )}
          <Button variant="outlined" onClick={handleCancel} disabled={isSaving}>
            {cancelButtonProps && cancelButtonProps.label ? cancelButtonProps.label : 'Cancel'}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions sx={{ pb: 0 }}></DialogActions>
      )}
    </Dialog>
  );
};

export default SaveCancelDialog;
