import { addMinutes, format, isValid } from 'date-fns';

export const formatPatterns = {
  LongLocalizedDate: 'P',
  LongLocalizedTime: 'p',
  LocalizedDateAndTime: 'Pp',
};

export const dateFormatOptions: ReadonlyArray<Record<string, string>> = [
  {
    value: 'MM/dd/yyyy',
    name: 'MM/dd/yyyy',
    longFormat: 'MM/dd/y pp',
    formatDate: 'MM/dd/y',
    fileNameFormat: 'MM_dd_y_HH_mm_ss',
    dateTimeInput: 'MM/dd/yyyy hh:mm a',
    dateInput: 'MM/dd/yyyy',
    LongLocalizedDate: 'P',
  },
  {
    value: 'dd/MM/yyyy',
    name: 'dd/MM/yyyy',
    longFormat: 'dd/MM/y pp',
    formatDate: 'dd/MM/y',
    fileNameFormat: 'dd_MM_y_HH_mm_ss',
    dateTimeInput: 'dd/MM/yyyy hh:mm a',
    dateInput: 'dd/MM/yyyy',
  },
];

export const defaultAppFormatPattern = dateFormatOptions[0].value;

export const getUtc = (datetime: Date) => {
  return addMinutes(datetime, datetime.getTimezoneOffset());
};

export const formatLocalized = (dateTime: string | Date, pattern: string = 'P'): string | null => {
  if (typeof dateTime === 'string' && (dateTime?.indexOf('9999-') === 0 || dateTime?.indexOf('0001-01-01') === 0)) {
    return '';
  }

  const value = typeof dateTime === 'string' ? new Date(dateTime) : dateTime;

  return isValid(value) ? format(value, pattern) : null;
};

export const formatByDefinedType = (dateTime: string | Date, type: string = 'longFormat'): string | null => {
  const setting = dateFormatOptions[0];
  const pattern = setting[type] || 'MM/dd/y pp';

  if (typeof dateTime === 'string' && (dateTime?.indexOf('9999-') === 0 || dateTime?.indexOf('0001-01-01') === 0)) {
    return '';
  }

  const value = typeof dateTime === 'string' ? new Date(dateTime) : dateTime;

  return isValid(value) ? format(value, pattern) : null;
};
