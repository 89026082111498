import { PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeBackground {
    sidebar?: string;
    section?: string;
    topBar?: string;
    footer?: string;
    creditBalance?: string;
    appPrimary?: string;
  }
  // allow configuration using `createTheme`
  interface TypeAction {
    menuItemHover?: string;
    menuItemSelected?: string;
  }

  interface TypeCell {
    menuItemHover?: string;
    menuItemSelected?: string;
  }

  interface TypeText {
    headerCell?: string;
    menuItem?: string;
    menuItemSelected?: string;
    textFooter?: string;
    creditText?: string;
    availableTill?: string;
    title?: string;
    nameUser?: string;
  }
}

export default function themePalette(): PaletteOptions {
  return {
    primary: {
      main: '#303030',
      dark: '#212121',
      light: '#595959',
    },
    background: {
      sidebar: '#FFFFFF',
      section: '#F5F5F5',
      creditBalance: 'rgba(0, 0, 0, 0.08)',
    },
    text: {
      menuItem: 'rgba(0,0,0, 0.8)',
      menuItemSelected: '#FFFFFF',
      headerCell: '#808080',
      textFooter: '#DDDDDD',
      creditText: '#000000',
      availableTill: '#787878',
      title: '#444',
      nameUser: '#333',
    },
    action: {
      menuItemHover: '#F5F5F5',
      menuItemSelected: '#303030',
    },
  };
}
