import { Box, Link, Paper, Stack } from '@mui/material';

export const AppFooter = () => {
  return (
    <Paper
      sx={{
        marginTop: 'calc(20% + 80px)',
        width: '100%',
        height: 50,
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'primary.main',
        zIndex: 1100,
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          color: 'text.textFooter',
          paddingLeft: 4,
        }}
      >
        © {new Date().getFullYear()} Apparel Manufacturing Co., Inc.
        <Stack direction="row" spacing={2} paddingX={4}>
          <Link href="https://www.apparelmanufacturing.com/" color="inherit" target="_blank">
            Website
          </Link>
          <Link href="https://www.apparelmanufacturing.com/about.htm" color="inherit" target="_blank">
            About Us
          </Link>
        </Stack>
      </Box>
    </Paper>
  );
};

export default AppFooter;
