import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { authorizationClient } from 'api';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import AppRoutes from 'app/constants/routes';

import { showNotification } from 'utils/toastNotification';

const validationSchema = Yup.object({
  username: Yup.string().nullable().required('Username is required.'),
  password: Yup.string().nullable().required('Password is required.'),
});

export default function NewSignIn() {
  const auth = useAuth();
  const [isSigningIn, setIsSigningIn] = React.useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [validationErr, setValidationErr] = React.useState<Yup.ValidationError | null>(null);
  const error = Boolean(searchParams.get('error'));

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const requestParam = {
      username: data.get('username')?.toString() ?? '',
      password: data.get('password')?.toString() ?? '',
    };

    try {
      await validationSchema.validate(requestParam, { abortEarly: false });
      const loginResult = await authorizationClient.signIn(requestParam);
      setIsSigningIn(true);

      if (loginResult) {
        await auth.signinRedirect();
      } else {
        showNotification({
          message: 'Invalid credentials. Please try again.',
          severity: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        setValidationErr(err);
        return;
      }
      console.log('', { err });
      showNotification({
        message: err.message || err.title || 'Invalid credentials. Please try again.',
        severity: 'error',
        autoHideDuration: 3000,
      });
    }

    setIsSigningIn(false);
  };

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [auth.isAuthenticated, navigate]);

  const getError = (path: string) => validationErr?.inner.find((x) => x.path === path)?.message;

  const renderSuccessfulLogin = () => {
    return (
      <Container component="main" maxWidth="md">
        <Alert icon={<CircularProgress color="success" size={20} />} severity="success" sx={{ width: 1, mt: 8 }}>
          You have successfully signed in
        </Alert>
      </Container>
    );
  };

  return auth.isAuthenticated && auth.isLoading ? (
    renderSuccessfulLogin()
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography fontSize={16} my={2}>
        Please enter your username and password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          disabled={isSigningIn}
          error={Boolean(getError('username'))}
          helperText={getError('username')}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          disabled={isSigningIn}
          error={Boolean(getError('password'))}
          helperText={getError('password')}
        />
        <LoadingButton
          type="submit"
          disabled={isSigningIn || auth.isLoading}
          loading={isSigningIn || auth.isLoading}
          loadingPosition="end"
          endIcon={<></>}
          fullWidth
          variant="contained"
          sx={{ my: 3 }}
        >
          Sign In
        </LoadingButton>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            navigate(`/auth/${AppRoutes.ForgotPassword}`);
          }}
        >
          Forgot Password
        </Link>

        {error && <Alert severity="error">Invalid credentials. Please try again.</Alert>}
      </Box>
    </Box>
  );
}
