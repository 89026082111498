import { appSettings } from 'app/constants/appSettings';

export const getFileNameFromBlobId = (blobId?: string) => {
  if (!blobId) {
    return '';
  }

  const timeStampIndex = blobId.indexOf('Z_');

  if (timeStampIndex > -1) {
    const clientAndFileName = blobId.substring(timeStampIndex + 2);

    const fileIndex = clientAndFileName.indexOf('_');

    if (fileIndex > -1) {
      const fileName = clientAndFileName.substring(fileIndex + 1);

      return fileName;
    }
  }

  return '';
};
export const getServerImageUrl = (blobImageId?: string, defaultImageUrl?: string) => {
  const imageUrl = blobImageId
    ? appSettings.apiUrl + '/api/images/file/' + blobImageId
    : defaultImageUrl
    ? defaultImageUrl
    : defaultProductImagePath;
  return imageUrl;
};

export const defaultProductImagePath = '/images/default-product.jpg';
