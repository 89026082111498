import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { imagesClient, webstoresClient } from 'api';
import { EntityWithName, WebstoreDetailViewModel, WebstoreSummary } from 'api/nswag';

import AppRoutes from 'app/constants/routes';
import { MenuItem } from 'app/navigationMenuItems';

export interface IWebstoreState {
  webstore?: WebstoreSummary;
  webstoreCatalogMenus?: MenuItem[];
  isLoadingWebstoreCatalogs: boolean;
  catalogGroups: EntityWithName[];
  personalization: string;
}

const initialState: IWebstoreState = {
  webstore: undefined,
  webstoreCatalogMenus: undefined,
  isLoadingWebstoreCatalogs: false,
  catalogGroups: [],
  personalization: '',
};

export const getWebstoreLogo = createAsyncThunk('webstores/getLogo', async (blobId: string) => {
  const response = await imagesClient.getImage(blobId);
  return response;
});

export const getWebstoreByCurrentUser = createAsyncThunk('webstores/getByCurrentUser', async () => {
  const response = await webstoresClient.getWebstoreByCurrentUser();
  return response;
});

export const getWebstoreCatalogs = createAsyncThunk('webstore/getWebstoreCatalogs', async () => {
  const webstoreDetail = await webstoresClient.getWebstoreCatalogs();

  return webstoreDetail;
});

const generateCatalogMenus = (webstoreDetail: WebstoreDetailViewModel) => {
  const webstoreId = webstoreDetail.id;
  const loadedCatalogs = webstoreDetail.catalogGroups || [];
  if (loadedCatalogs && loadedCatalogs.length > 0) {
    const catalogItems: MenuItem[] = loadedCatalogs.map((catalog) => ({
      path: `/${AppRoutes.Webstore}/${webstoreId}/${catalog.id}`,
      title: catalog.name ?? 'Catalog ' + catalog.id,
    }));
    return catalogItems;
  } else {
    return [] as MenuItem[];
  }
};

export const webstoresSlice = createSlice({
  name: 'webstores',
  initialState,
  reducers: {
    updatePersonalization: (state: IWebstoreState, action: PayloadAction<string>) => {
      state.personalization = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebstoreByCurrentUser.fulfilled, (state, action) => {
        state.webstore = action.payload;
      })
      .addCase(getWebstoreCatalogs.fulfilled, (state, action) => {
        state.webstoreCatalogMenus = generateCatalogMenus(action.payload);
        state.catalogGroups = action.payload.catalogGroups ?? [];
        state.isLoadingWebstoreCatalogs = false;
      })
      .addCase(getWebstoreCatalogs.pending, (state) => {
        state.isLoadingWebstoreCatalogs = true;
      })
      .addCase(getWebstoreCatalogs.rejected, (state) => {
        state.isLoadingWebstoreCatalogs = false;
      });
  },
});

export const { updatePersonalization } = webstoresSlice.actions;
const webstoreSliceReducer = webstoresSlice.reducer;
export default webstoreSliceReducer;
