import * as Yup from 'yup';

import { IFieldGroupTemplate } from 'types/FormGenerator';

import { IForgotPassword, IResetPassword } from './types';

export const defaultResetPassword: IResetPassword = {
  password: '',
  confirmPassword: '',
};

export const resetPasswordFieldGroupsTemplate: IFieldGroupTemplate[] = [
  {
    fields: [
      {
        name: 'password',
        type: 'password',
        label: 'New password',
        required: true,
        readOnly: false,
      },
      {
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm password',
        required: true,
        readOnly: false,
      },
    ],
  },
];

export const defaultForgotPassword: IForgotPassword = {
  email: '',
};

export const forgotPasswordFieldGroupsTemplate: IFieldGroupTemplate[] = [
  {
    fields: [
      {
        name: 'email',
        type: 'text',
        label: 'Email',
        required: true,
        readOnly: false,
      },
    ],
  },
];

export const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .nullable()
    .email('Please enter a valid email address (Ex: example@domain.com)')
    .required('Email is required.'),
});
