import { Card, CardContent, CardHeader, Divider, LinearProgress, Typography } from '@mui/material';
import { forwardRef } from 'react';

import { PageLoader } from 'components/loaders';
import BackdropLoader from 'components/loaders/BackdropLoader';

import { AccessControl } from 'features/auth';

// constant
const headerSX = {
  padding: 1,
  '& .MuiCardHeader-action': { mr: 0 },
};

//TODO: apply typing
interface IPageContainer {
  content?: boolean;
  contentSx?: object;
  action?: any;
  sx?: object;
  title?: any;
  isLoading?: boolean;
  children?: React.ReactNode;
  isInitilizing?: boolean;
  isBlocking?: boolean;
  [key: string]: any;
}

const PageContainer = forwardRef<HTMLDivElement, IPageContainer>(
  (
    {
      children,
      content = true,
      contentSx = {},
      action,
      sx = {},
      title,
      isLoading,
      allowedRoles = [],
      isInitilizing = false,
      isBlocking = false,
      ...others
    }: IPageContainer,
    ref
  ) => {
    return (
      <AccessControl allowedRoles={allowedRoles}>
        {/* page blocking to prevent user actions */}
        <BackdropLoader isOpen={isBlocking} />
        <Card
          ref={ref}
          elevation={0}
          {...others}
          sx={{
            ...sx,
          }}
        >
          {/* card header and action */}
          {title && (
            <CardHeader
              sx={headerSX}
              title={<Typography sx={{ fontSize: '1.5rem' }}>{title}</Typography>}
              action={action}
            />
          )}

          {/* content & header divider */}
          {title && <Divider />}
          {/* page initializing */}
          {isInitilizing && <PageLoader />}
          {/* page loading data */}
          {!isInitilizing && isLoading && <LinearProgress color="secondary" />}
          {/* card content */}
          {content && <CardContent sx={contentSx}>{children}</CardContent>}
          {!content && children}
        </Card>
      </AccessControl>
    );
  }
);

export default PageContainer;
