import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import themes from 'styles';

import AppRouters from 'app/AppRouters';
import ErrorBoundary from 'app/ErrorBoundary';
import { oidcConfig } from 'app/constants/appSettings';

import AlertsRenderer from 'features/alerts';
import { DialogsRenderer } from 'features/dialog';

const authConfig = {
  ...oidcConfig,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const App = () => {
  const theme = themes();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider {...authConfig}>
              <AppRouters />
            </AuthProvider>
            <ErrorBoundary>
              <DialogsRenderer />
              <AlertsRenderer />
            </ErrorBoundary>
          </LocalizationProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
