import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';

import themeComponent from './component';
import themePalette from './palette';
import themeTypography from './typography';

export const theme = (): Theme => {
  const themeOptions: ThemeOptions = {
    direction: 'ltr',
    palette: {
      mode: 'light',
      ...themePalette(),
    },
    typography: themeTypography(),
    components: themeComponent(),
  };

  const themes = createTheme(themeOptions);

  return themes;
};

export default theme;
